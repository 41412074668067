<template>
    <div>
        <div id="auth-window">
            <img id="profile-picture" src="https://cdn.interaapps.de/ia/icons/icon.svg">
            <h3 id="user-name-preview">{{ accountLink ? 'Just one step' : 'Register' }}</h3>
            <input autofocus v-model="username" placeholder="username" type="text">
            <input v-model="mail" placeholder="email" type="text">
            <input v-if="!accountLink" v-model="password" placeholder="password" type="password">

            <div id="account-link" v-if="accountLink">
                <img :src="accountLink.profile_picture">
                <div>
                    <h1>{{ accountLink.name + " via " + accountLink.platform.toLowerCase() }}</h1>
                    <h2>{{ accountLink.mail }}</h2>
                </div>
            </div>

            <div id="turnstile-check"></div>

            <div id="privacy-policies">
                <label for="privacy-policies-checkbox">I accept the <a target="_blank" style="color: #434343;"
                                                                       href="https://interaapps.de/privacy">privacy
                    policies</a> and terms of use</label> <input type="checkbox" id="privacy-policies-checkbox"
                                                                 v-model="privacyPoliciesAccepted">
            </div>
            <p class="error-message">{{ errorMessage }}</p>
            <button @click="register" class="button highlighted" id="login-button" :disabled="!turnStileToken">
                {{ accountLink ? 'Next' : 'Register' }}
            </button>

            <router-link id="register-action"
                         :to="'/login' + ($route.query.redirect ? '?redirect='+$route.query.redirect : '')"
                         style="float: none">Login
            </router-link>

        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helper'

export default {
    data: () => ({
        step: 0,
        username: "",
        password: "",
        mail: "",
        privacyPoliciesAccepted: false,
        errorMessage: "",
        accountLink: null,
        accountLinkCode: null,
        redirectUrl: "/",
        turnStileToken: null
    }),
    mounted() {
        if (this.$route.query.redirect) {
            if (!this.$route.query.redirect.includes("://"))
                this.redirectUrl = this.$route.query.redirect
        }
        if (this.$route.query.account_link) {
            console.log(this.$route.query.account_link);
            this.apiClient.get("/api/v2/auth/linkrequest/" + this.$route.query.account_link)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        this.password = "RANDOM-" + Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7)
                        this.accountLink = res
                        this.accountLinkCode = this.$route.query.account_link
                        this.username = res.name
                        this.mail = res.mail
                    }
                })
        }

        eventBus.$on('turnStileLoaded', () => {
            this.createTurnstile()
        })

        if (this.$store.state.app.turnStileLoaded) {
            this.createTurnstile()
        }
    },
    beforeDestroy() {
        if (process.env.VUE_APP_CF_TURNSTILE_SITE) {
            window.turnstile.remove()
        }
    },
    methods: {
        createTurnstile() {
            console.log("CReate new")
            if (process.env.VUE_APP_CF_TURNSTILE_SITE) {
                window.turnstile.render('#turnstile-check', {
                    sitekey: process.env.VUE_APP_CF_TURNSTILE_SITE,
                    callback: token => {
                        this.turnStileToken = token
                    }
                })
            }
        },
        register() {
            const data = {
                password: this.password,
                name: this.username,
                mail: this.mail,
                privacy_policies_accepted: this.privacyPoliciesAccepted,
                challenge_type: 'CLOUDFLARE_TURNSTILE',
                challenge: {
                    token: this.turnStileToken
                }
            }

            if (this.accountLink)
                data.profile_picture = this.accountLink.profile_picture

            this.apiClient.post("/api/v2/auth/register", data).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        this.apiClient.setApiKey(res.auth_key.key)
                            .then((user) => {
                                this.$router.push(this.redirectUrl);
                                if (this.accountLinkCode) {
                                    this.apiClient.post("/api/v2/auth/linkrequest/" + this.accountLinkCode)
                                        .then(() => this.$router.push(this.redirectUrl))
                                } else {
                                    this.$router.push(this.redirectUrl)
                                }
                                this.$router.push(this.redirectUrl);

                                this.apiClient.addUserAuthkey(user.id, res.auth_key.key)
                            })
                    } else {
                        this.errorMessage = res.message ? res.message : res.exception
                    }
                });
        }
    }
}
</script>
<style lang="scss" scoped>
#privacy-policies {
    input {
        display: inline-block;
        width: 15px;
    }
}

#account-link {
    margin-bottom: 8px;
    padding: 7px 8px;
    border: #0000000F solid 2px;
    border-radius: 10px;

    img {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px
    }

    div {
        display: inline-block;
        vertical-align: middle;
        width: fit-content;

        h1 {
            font-size: 20px;
            color: #545454;
        }

        h2 {
            font-size: 18px;
            color: #767676;
        }
    }
}
</style>